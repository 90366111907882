<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2 manage-shop-show w-50" v-loading="loaders.fetching">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <div>
                <h1>Manage Shop</h1>
            </div>
            <div>
                <base-button type="dark-blue" outline @click="$router.push({ name: 'admin.manage_shops.edit', })"> <i
                        class="fa" :class="{ 'fa-pen': manageShop, 'fa-plus': !manageShop }"></i> {{ `${manageShop ? 'Edit' :
                            'Add'}` }}</base-button>
            </div>
        </div>
        <manage-shop v-if="manageShop" :manage-shop="manageShop" />
        <div v-else class="mt-4 cardStyle text-center">
            <p class="m-5 font-weight-bold">
                No Shop Data Found!
            </p>
        </div>
    </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import ManageShop from "@/views/Components/SuperAdmin/ManageShop/Card";

export default {
    components: {
        BackButton,
        ManageShop
    },
    data() {
        return {
            loaders: {
                fetching: false,
            },
            manageShop: null,
        };
    },
    mounted() {
        this.getManageShop();
    },
    methods: {
        async getManageShop() {
            this.loaders.fetching = true;
            try {
                let response = await this.$store.dispatch('ManageShopModule/_getManageShop');
                let {
                    data: { data },
                } = response;
                this.manageShop = data;
            } catch (error) {

            }
            this.loaders.fetching = false;
        },
    },
};
</script>
<style scoped lang="scss"></style>
  